/* footer */
footer {
	background-color: #565655;
	color: #fff;
	padding-top: 25px;
	font-size: 16px;
	ul {
		@include ul-default;
	}

	/* footer nav */
	.footer--nav {
		margin-bottom: 25px;
		ul {
			li {
				margin: 0px 20px;
				@include inline-block;
				a {
					font-family: $bold;
					font-size: 18px;
					color: #fff;
				}
				&.active a {

					text-decoration: underline;
				}
			}
		}
	}
	/* footer nav */

	.footer__col {
		position: relative;
		text-align: center;
		padding-top: 35px;
		i {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			font-size: 22px;
			text-align: center;
			&.fa-envelope {
				font-size: 18px;
			}
		}
		a {
			color: #fff;
		}
		.footer__col__left {
			width: 120px;
			@include inline-block;
		}
		.footer__col__right {
			@include inline-block;
			width: calc(100% - 125px);
		}
		&.phones {
			text-align: left;
		}
	}
	.container-fluid {
		background-color: #126bb8;
		padding: 10px 0;
		margin-top: 15px;
		.container {
			position: relative;
		}
		a {
			color: #fff;
		}
		p {
			margin: 0;
			@include inline-block;
		}
		.artmedia {
			width: 260px;
			float: right;
			padding-right: 120px;
			position: relative;
			@include inline-block;
			img {
				position: absolute;
				right: 0;
				top: -3px;
			}
		}
		.socials {
			width: 175px;
			position: absolute;
			right: 300px;
			top: -3px;
			text-align: center;
			margin: 0 auto;
			li {
				margin: 0 5px;
				@include inline-block;
				a {
					display: block;
					font-size: 18px;
					&:hover {
						opacity: 0.7;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.footer--nav {
			ul li {
				margin: 0 10px;
				a {
					font-size: 16px;
				}
			}
		}
        .container-fluid {
            p {
                display: block;
                text-align: center;
            }
            .artmedia {
                display: block;
                text-align: left;
                float: none;
                margin: 10px auto;
            }
            .socials {
                position: relative;
                top: 0;
                right: 0;
                margin: 5px auto 0;
            }
        }
	}
	@media screen and (max-width: 991px) {
		font-size: 14px;
		a {
			font-size: 14px;
		}
		.footer--nav {
			ul li {
				margin: 0 5px;
				a {
					font-size: 14px;
				}
			}
		}
		.container-fluid {
			.artmedia {
				width: 245px;
			}
		}
	}
	@media screen and (max-width: 767px) {
		padding-top: 15px;
		.footer--nav {
			display: none;
		}
		.footer__col {
			padding: 0 0 0 25px;
			text-align: left;
			i {
				right: auto;
				left: 0;
				top: 2px;
				font-size: 18px;
				&.fa-envelope {
					font-size: 14px;
				}
			}
			&.location, &.email {
				margin-bottom: 15px;
			}
			ul li {
				margin-bottom: 5px;
			}
		}
	}
}
/* footer */