/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'open_sansitalic';
    src: url('../fonts/opensans-italic-webfont.eot');
    src: url('../fonts/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-italic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-italic-webfont.woff') format('woff'),
         url('../fonts/opensans-italic-webfont.ttf') format('truetype'),
         url('../fonts/opensans-italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/open-sans-bold-webfont.eot');
    src: url('../fonts/open-sans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans-bold-webfont.woff2') format('woff2'),
         url('../fonts/open-sans-bold-webfont.woff') format('woff'),
         url('../fonts/open-sans-bold-webfont.ttf') format('truetype'),
         url('../fonts/open-sans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'open_sansregular';
$bold: 'open_sansbold';
$italic: 'open_sansitalic';
$light: 'open_sanslight';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}
*:after,*:before {
	line-height: 1;
}
body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	color: #353535;
	padding-top: 157px;
	margin: 0;
	@media screen and (max-width: 1199px) {
		padding-top: 134px;
	}
	@media screen and (max-width: 991px) {
		padding-top: 67px;
		font-size: 16px;
	}
}

h1 {
	display: block;
	font-family: $bold;
	font-size: 42px;
	text-transform: uppercase;
	color: #d4d4d4;
	text-align: center;
	margin-bottom: 25px;
	line-height: 1;
	text-align: center;
	@media screen and (max-width: 991px) {
		font-size: 28px;
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		word-break: break-all;
	}
}
h2 {
	display: table;
	position: relative;
	font-family: $bold;
	font-size: 42px;
	color: #565655;
	padding-bottom: 25px;
	margin: 0 auto 20px;
	text-align: center;
	&:after {
		content: '';
		height: 3px;
		width: 40px;
		background-color: #126bb8;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
	}
	a {
		font-size: 42px!important;
		font-family: $bold;
		color: #565655;
		display: block;
	}
	@media screen and (max-width: 991px) {
		font-size: 26px;
		padding-bottom: 10px;
		a {
			font-size: 26px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 22px;
		a {
			font-size: 22px!important;
		}
	}
}
h3 {
	font-family: $bold;
	font-size: 24px;
	color: #353535;
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
}
h4 {
	font-family: $bold;
	font-size: 20px;
	color: #353535;
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #353535;
	text-align: center;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #353535;
}
a {
	transition: 350ms;
	color: #353535;
	&:focus {
		color: #353535;
		text-decoration: none;
	}
	&:hover {
		color: #126bb8;
	}
}
input, textarea, button {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	textarea,
	input[type="text"],
	input[type="email"],
	input[type="phone"] {
		font-family: $regular;
		font-size: 16px;
		background-color: #fff;
		padding: 5px 15px;
		border: 1px solid #126bb8;
		margin-bottom: 15px;
	}
	a,p {
		font-size: 18px;
		text-decoration: none;
	}
	@media screen and (max-width: 991px) {
		a,p {
			font-size: 16px;
		}
	}
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}
.btn {
	display: table;
	width: auto!important;
	background-color: #126bb8!important;
	font-family: $bold!important;
	font-size: 18px;
	color: #fff;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #3361eb;
	padding: 12px 40px!important;
	border: 1px solid #126bb8;
	transition: 350ms;
	&:focus, &:active {
		color: #fff;
		background-color: #126bb8!important;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #126bb8!important;
	}	
	@media screen and (max-width: 991px) {
		font-size: 16px;
		padding: 9px 20px 10px!important;
	}
	@media screen and (max-width: 768px) {
		font-size: 16px;
		padding: 7px 20px 8px!important;
	}
}

.check--list {
	@include ul-default;
	margin: 10px 0px;
	li {
		display: block;
		padding-left: 25px;
		position: relative;
		margin-bottom: 5px;
		font-size: 18px;
		&:before {
			content: '\f046';
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #126bb8;
			position: absolute;
			left: 0;
			top: 4px;
		}
	}
	@media screen and (max-width: 991px) {
		li {
			font-size: 16px;
			&:before {
				font-size: 18px;
				top: 2px;
			}
		}
	}
}

/* header */
header {
	padding-top: 10px;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background-color: #fff;
	z-index: 9;

	/* header logo */
	.logo {
		width: 255px;
		@include inline-block;
		img {
			display: block;
			width: 100%;
		}
	}
	/* header logo */

	/* header email socials phones */
	.header--right {
		width: calc(100% - 260px);
		font-size: 0;
		box-sizing: border-box;
		padding-left: 35px;
		margin-top: 15px;
		@include inline-block;
		.header--right__col {
			width: 25%;
			font-size: 18px;
			box-sizing: border-box;
			padding-right: 15px;
			@include inline-block;
			.socials {
				@include ul-default;
				display: block;
				margin: 2px 0 6px 0;
				li {
					margin-right: 10px;
					@include inline-block;
					a {
						font-size: 22px;
						color: #126bb8;
						&:hover {
							color: #565655;
						}
					}
				}
			}
			.email {
				font-size: 15px;
				text-decoration: underline;
				color: #353535;
				position: relative;
				padding-left: 25px;
				&:after {
					content: '\f0e0';
					display: block;
					font-family: 'fontAwesome';
					font-size: 16px;
					color: #126bb8;
					position: absolute;
					left: 0;
					top: 2px;
				}
				&:hover {
					text-decoration: none;
				}
			}
			> span {
				display: block;
				margin-bottom: 10px;
			}
			&.phone {
				a {
					display: block;
					color: #353535;
					text-decoration: none;
					position: relative;
					padding-left: 25px;
					&:after {
						content: '\f095';
						display: block;
						font-family: 'fontAwesome';
						font-size: 20px;
						color: #126bb8;
						position: absolute;
						top: 3px;
						left: 0;
					}
					span {
						font-family: $bold;
						color: #126bb8;
						display: inline;
					}
				}
			}
		}
		.close {
			display: none;
		}
	}
	/* header email socials phones */

	.container-fluid {
		background-color: #126bb8;
		color: #fff;
		margin-top: 10px;
		.container {
			position: relative;
		}
	}
	/* header nav */
	.nav {
		@include inline-block;
		ul {
			@include ul-default;
		}
		> ul > li {
			position: relative;
			margin: 0 18px 0 18px;
			@include inline-block;
			> a {
				display: block;
				position: relative;
				font-family: $bold;
				font-size: 18px;
				color: #fff;
				padding: 7px 0px 8px 0px;
				text-decoration: none;
				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 1px;
					background-color: #fff;
					position: absolute;
					left: 0;
					bottom: 10px;
					transition: 300ms;
					opacity: 0;
				}
				&:hover:before {
					opacity: 1;
				}
			}
			&.submenu {
				> a {
					padding-right: 20px;
					&:after {
						content: '';
						width: 0;
						height: 0;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 6px solid #fff;
						position: absolute;
						right: 4px;
						top: 50%;
						right: 0;
						margin-top: -3px;
					}
				}
			}
			&.active {
				> a:before {
					opacity: 1;
				}
			}
			ul {
				display: none;
				width: 160px;
				background-color: #fff;
				position: absolute;
				top: 40px;
				left: 0;
				z-index: 1;
				box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
				li {
					a {
						display: block;
						padding: 8px 20px;
						color: #353535;
						line-height: 1;
						&:hover {
							text-decoration: none;
							color: #126bb8;
						}
					}
					&.active {
						a {
							color: #126bb8;
						}
					}
				}
			}
		}
	}
	.nav--btn {
		display: none;
	}
	/* header nav */	

	/* header search */
	.search--btn {
		width: 17px;
		height: 17px;
		position: relative;
		float: right;
		font-size: 17px;
		color: #fff;
		margin-top: 7px;
		@include inline-block;
		&:after {
			content: '\f002';
			font-family: 'fontAwesome';
		}
		&:hover {
			text-decoration: none;
		}
	}
	.search {
		display: none;
		width: 370px;
		background-color: #fff;
		position: absolute;
		top: 40px;
		right: 15px;
		border: 1px solid #126bb8;
		box-sizing: border-box;
		padding-right: 30px;
		z-index: 1;
		input[type="text"] {
			width: 100%;
			padding: 9px 15px;
			box-sizing: border-box;
			border: none;
			font-size: 16px;
		}
		.close {
			display: block;
			position: absolute;
			top: 9px;
			right: 8px;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #126bb8;
			opacity: 1;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	/* header search */

	@media screen and (max-width: 1199px) {
		.logo {
			width: 120px;
		}
		.header--right {
			padding-left: 20px;
			width: calc(100% - 125px);
		}

		.nav {
			> ul > li {
				margin: 0 12px;
				> a {
					font-size: 16px;
					padding: 8px 0 9px 0;
				}
			}
		}
		.search {
			top: 38px;
		}
	}

	@media screen and (max-width: 991px) {
		box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
		.container {
			position: relative;
		}
		.logo {
			display: block;
			margin: 0 auto;
		}
		.header--right {
			display: none;
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 10px;
			background-color: rgba(255,255,255,0.9);
			margin: 0;
			z-index: 9;
			.header--right__col {
				display: table;
				padding: 0;
				text-align: center;
				margin: 0 auto 10px auto;
				&:nth-of-type(1) {
					display: none;
				}
				&.phone {
					a {
						display: table;
						margin: 0 auto;
					}
				}
				> span {
					margin-bottom: 5px;
				}
			}
			.close {
				display: block;
				width: 25px;
				height: 25px;
				position: absolute;
				top: 10px;
				right: 10px;
				font-family: 'FontAwesome';
				font-size: 25px;
				color: #126bb8;
				opacity: 1;
				outline: none;
				text-align: center;
				&:after {
					content: '\f00d';
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
		.header--right--btn {
			display: block;
			position: absolute;
			right: 15px;
			top: 7px;
			height: 30px;
			width: 20px;
			span {
				display: block;
				width: 4px;
				height: 4px;
				background-color: #126bb8;
				border-radius: 100%;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				&:nth-of-type(1) {
					top: 5px;
				}
				&:nth-of-type(2) {
					top: 13px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
		}
		.nav {
			display: none;
			> ul > li {
				display: block;
				margin: 0;
				> a {
					display: table;
					padding: 5px 0;
					&:before {
						bottom: 3px;
					}
				}
				> ul {
					width: 100%;
					position: relative;
					top: 0;
					left: 0;
				}
			}
		}
		.nav--btn {
			display: block;
			width: 35px;
			height: 30px;
			position: absolute;
			left: 15px;
			top: -48px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #126bb8;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.search--btn {
			width: 24px;
			height: 24px;
			position: absolute;
			top: -47px;
			right: 45px;
			color: #126bb8;
			float: none;
			font-size: 24px;
			margin: 0;
			&:after {
				display: block;
			}
		}
		.search {
			top: 0px;
		}
	}
	@media screen and (max-width: 768px) {
		.header--right .header--right__col {
			width: 100%;
		}
		.search {
			width: 100%;
			left: 0;
			right: 0;
		}
	}
}
/* header */