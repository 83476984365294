/* page content */
.page {
	padding-bottom: 35px;
	font-size: 18px;
	.news1, .news {
		.date {
			color: #126bb8;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #126bb8;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #126bb8;
		}
	}
	a {
		text-decoration: underline;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #126bb8;
			text-decoration: none;
		}
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 16px;
	}
	@media screen and (max-width: 480px) {
		p img, p strong img, p iframe {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #126bb8;
			@include inline-block;
			a {
				font-size: 16px;
				text-decoration: none;
			}
			&:after {
				content: '/';
				font-size: 18px;
				margin: 2px 2px 0px 7px;
				color: #353535;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}
/* breadcrumbs */

/* page contacts */
.map {
	position: relative;
	height: 350px;
	.map__block {
		width: 350px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		padding: 25px 15px;
		background-color: rgba(0,0,0,0.6);
		font-size: 16px;
		color: #fff;
		text-align: center;
		box-sizing: border-box;
		z-index: 1;
		ul {
			@include ul-default;
		}
		ul > li {
			margin-bottom: 15px;
		}
		a {
			font-size: 16px;
			color: #fff;
			text-decoration: none;
		}
		.map__block__row {
			position: relative;
			padding-top: 35px;
			margin-bottom: 15px;
			i {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				text-align: center;
				font-size: 20px;
				&.fa-map-marker, &.fa-phone {
					font-size: 24px;
				}
			}
			ul li {
				width: 280px;
				margin: 0 auto 5px;
				.map__block__row__left {
					width: 115px;
					text-align: left;
					@include inline-block;
				}
				.map__block__row__right {
					width: calc(100% - 140px);
					text-align: left;
					@include inline-block;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		height: auto;
		.map__block {
			position: relative;
			top: 0;
			left: 0;
			width: 100%;	
			background-color: transparent;
			color: #353535;
			a {
				color: #353535;
			}
		}
		> ymaps {
			height: 200px!important;
		}
	}
}
.form--contacts {
	margin-top: 50px;
	> p {
		margin-bottom: 25px;
	}
	.col-sm-4:nth-of-type(2) {
		padding: 0;
	}
	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%;
		box-sizing: border-box;
	}
	.btn {
		float: right;
	}
	@media screen and (max-width: 991px) {
		margin-top: 25px;
		.col-sm-4:nth-of-type(2) {
			padding: 0 15px;
		}
	}
}
/* page contacts */

/* page pagination */
.pagination {
	ul {
		@include ul-default;
		li {
			margin-bottom: 10px;
			@include inline-block;
			a {
				text-decoration: none;
				width: 30px;
				height: 30px;
				display: block;
				border: 2px solid transparent;
				font-size: 16px;
				text-align: center;
				box-sizing: border-box;
				padding: 5px 2px 0px;
				outline: none;
				line-height: 1;
				&:hover {
					border: 2px solid #126bb8;
					color: #126bb8;
				}
			}
			&.active {
				a {
					border: 2px solid #126bb8;
				}
			}
			&.prev, &.next {
				a {
					position: relative;
					&:hover {
						border: 2px solid transparent;
					}
					&:after {
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-top: 5px solid transparent;
						border-bottom: 5px solid transparent;
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						margin: -5px auto;
						transition: 350ms;
					}
				}
			}	
			&.prev a {
				&:after {
					border-right: 7px solid #292929;
				} 
				&:hover:after {
					border-right: 7px solid #126bb8;
				}
			}
			&.next a {
				&:after {
					border-left: 7px solid #292929;
				} 
				&:hover:after {
					border-left: 7px solid #126bb8;
				}
			}
		}
	}
}

/* modal */
.modal {
	.modal-dialog {
		width: 480px;
		.modal-content {
			border-radius: 0px;
			h3 {
				margin: 0;
			}
			.close {
				position: absolute;
				width: 25px;
				height: 25px;
				font-family: 'FontAwesome';
				font-size: 25px;
				opacity: 1;
				right: 10px;
				top: 14px;
				outline: none;
				&:after {
					content: '\f00d';
				}
				&:hover {
					opacity: 0.7;
				}
			}
			form {
				display: block;
				padding-bottom: 45px;
				input[type="text"],
				input[type="text"]
				textarea {
					width: 100%;
					padding: 5px 10px;
					border: 1px solid #126bb8;
					margin-bottom: 15px;
				}
				.btn {
					float: right;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.modal-content h3 {
			font-size: 28px;
		}
	}
	@media screen and (max-width: 767px) {
		.modal-dialog {
			width: auto;
			max-width: auto;
			.modal-content h3 {
				font-size: 18px;
			}
		}
	}
}

/* modal */

/* call modal */
.call-btn {
	position: fixed;
	bottom: 60px;
	right: 35px;
	display: table;
	background-color: #126bb8;
	font-family: 'FontAwesome';
	border-radius: 100%;
	text-decoration: none!important;
	width: 80px;
	height: 80px;
	text-align: center;
	padding: 25px 5px 5px 5px;
	box-shadow: 0px 0px 35px rgba(0,0,0,0.7);
	z-index: 1;
	&:hover {
		animation-name: phone;
	  	animation-iteration-count: 1;
	  	animation-duration: 0.7s;
	}
	i {
		display: block;
		font-size: 34px;
		color: #fff;
	}
	&:after,&:before  {
		content: '';
		width: 90%;
		height: 90%;
		background-color: transparent;
		border: 1px solid #fff;
		position: absolute;
		top: 5%;
		left: 5%;
		border-radius: 100%;
		animation-iteration-count: infinite;
	    animation-duration: 1.2s;
	    animation-name: ring;
	}
	&:after {
		animation-delay: 0.6s;
	}
	&:before {
		animation-delay: 0.8s;
	}
	@media screen and (max-width: 991px) {
		width: 50px;
		height: 50px;
		padding-top: 13px;
		right: 15px;
		bottom: 30px;
		i {
			font-size: 24px;
		}
	}
}
@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}
/* call form */ 


/* page content */