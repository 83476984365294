/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			margin: 0 auto;
			margin-top: -50px;
			.slider__block__title {
				display: table;
				padding: 10px 25px;
				font-family: $bold;
				background-color: rgba(0,0,0,0.9);
				font-size: 42px;
				text-transform: uppercase;
				text-align: center;
				color: #fff;
				margin: 0 auto;
			}
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				background-color: #fff;
				width: 15px;
				height: 15px;
				border-radius: 100%;
				border: 3px solid #126bb8;
				transition: 350ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #126bb8;
				}
			}
			&.slick-active {
				button {
					background-color: #126bb8;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.slick-slide .slider__block {
			margin-top: -35px;
			.slider__block__title {
				font-size: 36px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.slick-slide .slider__block {
			margin-top: -30px;
			.slider__block__title {
				font-size: 28px;
			}
		}
		.slick-dots {
			bottom: 15px;
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide .slider__block {
			margin-top: -25px;
			.slider__block__title {
				font-size: 22px;
				background-color: rgba(0,0,0,0.7);
			}
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			img {
				height: 200px;
				width: auto;
			}
			.slider__block {
				top: auto;
				bottom: 60px;
				margin: 0 auto;
				.slider__block__title {
					font-size: 18px;
				}
			}
		}
	}
}
/* main slider */

/* main services */
.services {
	.container {
		padding-bottom: 20px;
	}
	.services__col {
		display: block;
		margin: 15px 0;
		text-decoration: none!important;
		.services__col__img {
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				height: auto;
				transition: 400ms;
			}
		}
		.services__col__title {
			display: block;
			font-family: $bold;
			font-size: 24px;
			color: #fff;
			text-align: center;
			text-decoration: none!important;
			background-color: #565655;
			transition: 350ms;
			padding: 16px 15px 18px;
			line-height: 1;
		}
		&:hover {
			.services__col__img img {
				transform: scale(1.05);
			}
			.services__col__title {
				background-color: #126bb8;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.container {
			padding-bottom: 0px;
		}
		.services__col .services__col__title {
			font-size: 22px;
			padding: 10px 10px;
		}
	}
	@media screen and (max-width: 767px) {
		.services__col .services__col__title {
			font-size: 20px;
		}
	}
}
/* main services */

/* main about */
.about {
	min-height: 750px;
	h1 {
		margin-top: 0;
	}
	.about__block {
		position: relative;
		padding: 150px 0px 0px 570px;
		.about__block__img {
			position: absolute;
			left: 85px;
			top: 50px;
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				border: 10px solid #e7f0f8;
				top: -50px;
				left: -85px;
				z-index: -1;
			}
			img {
				display: block;
				width: 100%;
				z-index: 1;
			}
		}
		.check--list {
			li {
				margin-right: 35px;
				@include inline-block;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.about__block {
			padding: 0;
			.about__block__img {
				position: relative;
				top: 0;
				left: 0;
				margin-bottom: 10px;
				&:after {
					display: none;
				}
			}
		}
	}
}
/* main about */

/* main partners */
.partners {
	position: relative;
	> img {
		display: block;
		width: 100%;
	}
	.partners__slider {
		position: absolute;
		width: 1170px;
		left: 0;
		right: 0;
		top: 50%;
		margin: -50px auto 0;
		.slick-slide {
			margin: 0 15px;
			img {
				display: block;
				width: 100%;
			}
		}
		.slick-arrow {
			width: 20px;
			height: 25px;
			text-align: center;
			z-index: 1;
			&:before {
				display: none;
			}
			&:after {
				display: block;
				font-family: 'fontAwesome';
				font-size: 25px;
				color: #fff;
				transition: 350ms;
			}
			&:hover {
				&:after {
					color: #126bb8;
				}
			}
			&.slick-prev {
				&:after {
					content: '\f053';
				}
			}
			&.slick-next {
				&:after {
					content: '\f054';
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		padding: 25px 0px;
		.partners__slider {
			width: auto;
			position: relative;
			top: 0;
			padding: 0 25px;
			margin: 0;
			.slick-arrow {
				&:after {
					color: #353535;
				}
				&.slick-prev {
					left: 10px;
				}
				&.slick-next {
					right: 10px;
				}
			}
		}
		> img {
			display: none;
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 0px;
	}
}
/* main partners */

/* main news */
.news {
	.news__col {
		display: block;
		.news__col__img {
			display: block;
			width: 100%;
			overflow: hidden;
		}
		.news--date {
			display: table;
			font-size: 16px;
			color: #fff;
			background-color: #126bb8;
			text-align: center;
			padding: 5px 10px;
			margin: 25px 0 10px 0;
		}
		h3 {
			margin-top: 0px;
			transition: 350ms;
		}
		&:hover {
			color: #353535;
			h3 {
				color: #126bb8;
				text-decoration: underline;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.news__col {
			 .news__col__img {
				img {
					display: block;
					width: 100%;
				}
			}
			h3 {
				font-size: 20px;
			}
		}
	}
}
/* main news */

/* main content */